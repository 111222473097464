import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/spkAdjustApproval'

export default {
  get (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  signing (params) {
    return httpClient.post(`${endpoint}/signing`, params)
  }
}
